import { useEffect, useMemo, useState } from 'react';
import { Home } from '@/src/pages/Home';
import { Rules } from '@/src/pages/Rules';
import { Choice } from '@/src/pages/Choice';
import { Game } from '@/src/pages/Game';
import { Final } from '@/src/pages/Final';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Preloader } from '../Preloader';
import { TCard } from '@/src/constants';

export const App = () => {
  const [page, setPage] = useState('home'); // home / rules / choice / game / final

  const [style, setStyle] = useState(''); // ai / loveis / penguins / words

  const [finalCards, setFinalCards] = useState<TCard[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const changePage = (pageName: string) => {
    setPage(pageName);
  };

  const changeStyle = (styleName: string) => {
    setStyle(styleName);
  };

  const cardsToFinal = (cards: TCard[]) => {
    setFinalCards(cards);
  };

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home changePage={changePage} />;
      case 'rules':
        return <Rules changePage={changePage} />;
      case 'choice':
        return <Choice changePage={changePage} changeStyle={changeStyle} />;
      case 'game':
        return (
          <Game
            changePage={changePage}
            style={style}
            cardsToFinal={cardsToFinal}
          />
        );
      case 'final':
        return <Final style={style} finalCards={finalCards} />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  return isLoading ? (
    <Preloader />
  ) : (
    <div className="app">
      {page !== 'game' && <Header />}
      {renderCurrentPage}
      {page !== 'game' && <Footer />}
    </div>
  );
};
