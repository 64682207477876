import { useEffect } from 'react';
import styles from './Choice.module.scss';
import classNames from 'classnames';
import { useMediaQuery } from 'usehooks-ts';
import sendEventToCounters from '@/src/counterEvents';

import { AI, LOVEIS, PENGUINS, WORDS } from '@/src/constants';

type TProps = {
  changePage: (s: string) => void;
  changeStyle: (s: string) => void;
};

export const Choice = ({ changePage, changeStyle }: TProps) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  const isMediaQueryLess1020 = useMediaQuery('(max-width: 1020px)');

  const handleGoNext = (style: string) => {
    changeStyle(style);

    switch (style) {
      case AI:
        sendAnalytics('ai');
        break;
      case LOVEIS:
        sendAnalytics('romantic');
        break;
      case PENGUINS:
        sendAnalytics('animal');
        break;
      case WORDS:
        sendAnalytics('text');
        break;
      default:
        break;
    }

    changePage('game');
  };

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'stickers', label });
  };

  return (
    <div className={styles.choice}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.title}>
            Выберите, что вам ближе по&#160;духу:
          </div>
          <div className={styles.list}>
            <div className={styles.wrapper}>
              <button
                className={classNames(styles.item, styles.item1)}
                onClick={() => handleGoNext(WORDS)}
              >
                <p>
                  Ничего лишнего&#160;–
                  <br />
                  только текст!
                </p>
                <span>
                  Хотите
                  <br />
                  в&#160;таком стиле
                  <br />
                  стикерпак?
                </span>
              </button>
            </div>
            <div className={styles.wrapper}>
              <button
                className={classNames(styles.item, styles.item2)}
                onClick={() => handleGoNext(PENGUINS)}
              >
                <p>
                  Любимое тотемное
                  <br />
                  животное
                </p>
                <span>
                  Хотите
                  <br />
                  в&#160;таком стиле
                  <br />
                  стикерпак?
                </span>
              </button>
            </div>
            <div className={styles.wrapper}>
              <button
                className={classNames(styles.item, styles.item3)}
                onClick={() => handleGoNext(AI)}
              >
                <p>
                  Творческий
                  <br />
                  взгляд&#160;ИИ
                </p>
                <span>
                  Хотите
                  <br />
                  в&#160;таком стиле
                  <br />
                  стикерпак?
                </span>
              </button>
            </div>
            <div className={styles.wrapper}>
              <button
                className={classNames(styles.item, styles.item4)}
                onClick={() => handleGoNext(LOVEIS)}
              >
                <p>
                  Классика романтики –
                  <br />
                  в&#160;каждом вкладыше
                </p>
                <span>
                  Хотите
                  <br />
                  в&#160;таком стиле
                  <br />
                  стикерпак?
                </span>
              </button>
            </div>
          </div>
          {isMediaQueryLess1020 && (
            <div className={styles.bottom}>
              <p>
                В&#160;каком
                <br />
                стиле хотите <br />
                стикерпак?
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
