/* eslint-disable @typescript-eslint/no-unused-vars */

import loveai1 from '../images/stickers/love-1-1.png';
import loveai2 from '../images/stickers/love-1-2.png';
import loveai3 from '../images/stickers/love-1-3.png';
import loveai4 from '../images/stickers/love-1-4.png';
import loveai5 from '../images/stickers/love-1-5.png';
import loveai6 from '../images/stickers/love-1-6.png';
import kindnessai1 from '../images/stickers/kindness-1-1.png';
import kindnessai2 from '../images/stickers/kindness-1-2.png';
import kindnessai3 from '../images/stickers/kindness-1-3.png';
import kindnessai4 from '../images/stickers/kindness-1-4.png';
import kindnessai5 from '../images/stickers/kindness-1-5.png';
import kindnessai6 from '../images/stickers/kindness-1-6.png';
import trustai1 from '../images/stickers/trust-1-1.png';
import trustai2 from '../images/stickers/trust-1-2.png';
import trustai3 from '../images/stickers/trust-1-3.png';
import trustai4 from '../images/stickers/trust-1-4.png';
import trustai5 from '../images/stickers/trust-1-5.png';
import trustai6 from '../images/stickers/trust-1-6.png';
import understandingai1 from '../images/stickers/understanding-1-1.png';
import understandingai2 from '../images/stickers/understanding-1-2.png';
import understandingai3 from '../images/stickers/understanding-1-3.png';
import understandingai4 from '../images/stickers/understanding-1-4.png';
import understandingai5 from '../images/stickers/understanding-1-5.png';
import understandingai6 from '../images/stickers/understanding-1-6.png';
import loyaltyai1 from '../images/stickers/loyalty-1-1.png';
import loyaltyai2 from '../images/stickers/loyalty-1-2.png';
import loyaltyai3 from '../images/stickers/loyalty-1-3.png';
import loyaltyai4 from '../images/stickers/loyalty-1-4.png';
import loyaltyai5 from '../images/stickers/loyalty-1-5.png';
import loyaltyai6 from '../images/stickers/loyalty-1-6.png';
import respectai1 from '../images/stickers/respect-1-1.png';
import respectai2 from '../images/stickers/respect-1-2.png';
import respectai3 from '../images/stickers/respect-1-3.png';
import respectai4 from '../images/stickers/respect-1-4.png';
import respectai5 from '../images/stickers/respect-1-5.png';
import respectai6 from '../images/stickers/respect-1-6.png';

export const getImg = (img: string) => {
  switch (img) {
    case 'loveai1':
      return loveai1;
    case 'loveai2':
      return loveai2;
    case 'loveai3':
      return loveai3;
    case 'loveai4':
      return loveai4;
    case 'loveai5':
      return loveai5;
    case 'loveai6':
      return loveai6;
    case 'kindnessai1':
      return kindnessai1;
    case 'kindnessai2':
      return kindnessai2;
    case 'kindnessai3':
      return kindnessai3;
    case 'kindnessai4':
      return kindnessai4;
    case 'kindnessai5':
      return kindnessai5;
    case 'kindnessai6':
      return kindnessai6;
    case 'trustai1':
      return trustai1;
    case 'trustai2':
      return trustai2;
    case 'trustai3':
      return trustai3;
    case 'trustai4':
      return trustai4;
    case 'trustai5':
      return trustai5;
    case 'trustai6':
      return trustai6;
    case 'understandingai1':
      return understandingai1;
    case 'understandingai2':
      return understandingai2;
    case 'understandingai3':
      return understandingai3;
    case 'understandingai4':
      return understandingai4;
    case 'understandingai5':
      return understandingai5;
    case 'understandingai6':
      return understandingai6;
    case 'loyaltyai1':
      return loyaltyai1;
    case 'loyaltyai2':
      return loyaltyai2;
    case 'loyaltyai3':
      return loyaltyai3;
    case 'loyaltyai4':
      return loyaltyai4;
    case 'loyaltyai5':
      return loyaltyai5;
    case 'loyaltyai6':
      return loyaltyai6;
    case 'respectai1':
      return respectai1;
    case 'respectai2':
      return respectai2;
    case 'respectai3':
      return respectai3;
    case 'respectai4':
      return respectai4;
    case 'respectai5':
      return respectai5;
    case 'respectai6':
      return respectai6;
    default:
      break;
  }
};
