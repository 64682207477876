import styles from './Footer.module.scss';
import sendEventToCounters from '@/src/counterEvents';

import logo from '../../images/hh.svg';

export const Footer = () => {
  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'footer', label });
  };

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.left}>
            © ДОМ.РФ<span>|</span>
            <img src={logo} alt="" />
            Бренд-центр 2024
          </div>
          <a
            href="https://xn--d1aqf.xn--p1ai/career/vacancies/?utm_source=domrf_hh&utm_medium=referral&utm_campaign=special_project"
            target="_blank"
            rel="noreferrer"
            className={styles.vacancyLink}
            onClick={() => sendAnalytics('work')}
          >
            Работа по&#160;любви
          </a>
        </div>
      </div>
    </div>
  );
};
