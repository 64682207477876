import { useEffect } from 'react';
import styles from './Rules.module.scss';
import classNames from 'classnames';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  changePage: (s: string) => void;
};

export const Rules = ({ changePage }: TProps) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  const handleGoNext = () => {
    sendAnalytics('start');
    changePage('choice');
  };

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'discript', label });
  };

  return (
    <div className={styles.rules}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.title}>Что нужно делать:</div>
          <div className={styles.list}>
            <div className={styles.wrapper}>
              <div className={classNames(styles.item, styles.item1)}>
                <div className={styles.num}>
                  <span>1</span>
                </div>
                <p>
                  Выбирайте стиль игры&#160;– он&#160;повлияет на&#160;вид
                  стикеров
                </p>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={classNames(styles.item, styles.item2)}>
                <div className={styles.num}>
                  <span>2</span>
                </div>
                <p>
                  Ставьте лайк цитатам, которые хотите добавить в&#160;стикерпак
                </p>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={classNames(styles.item, styles.item3)}>
                <div className={styles.num}>
                  <span>3</span>
                </div>
                <p>Забирайте себе результат!</p>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <button
              className={classNames('button', styles.button)}
              onClick={handleGoNext}
            >
              Начать
            </button>
            <div className={styles.slogan}>
              <p>Готовы начать?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
