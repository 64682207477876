import { useEffect, useState } from 'react';
import 'swiper/css';
import styles from './Cards.module.scss';
import classNames from 'classnames';

import { type Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Navigation } from 'swiper/modules';

import { WORDS, TCard } from '@/src/constants';
import { createText } from '@/src/utils/createText';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  style: string;
  cards: TCard[];
  likeCard: (card: TCard) => void;
  dislikeCard: (card: TCard) => void;
  goFinal: () => void;
};

export const Cards = ({
  style,
  cards,
  likeCard,
  dislikeCard,
  goFinal,
}: TProps) => {
  const [activeCard, setActiveCard] = useState(1);

  useEffect(() => {
    if (activeCard === 6) {
      setTimeout(() => {
        goFinal();
      }, 1000);
    }
  }, [cards]);

  const [swiper, setSwiper] = useState<SwiperType>();

  const handleLike = () => {
    likeCard(cards[activeCard - 1]);
    sendEventToCounters({ action: 'card' + activeCard, label: 'like' });
    sendEventToCounters(cards[activeCard - 1].analytics);
    if (swiper) {
      setTimeout(() => {
        swiper.slideNext();
      }, 500);
    }
  };

  const handleDislike = () => {
    dislikeCard(cards[activeCard - 1]);
    sendEventToCounters({ action: 'card' + activeCard, label: 'dizlike' });
    if (swiper) {
      setTimeout(() => {
        swiper.slideNext();
      }, 500);
    }
  };

  const renderSlides = () => {
    return cards.map((card: TCard) => {
      return (
        <SwiperSlide key={card.value}>
          <div
            className={classNames(
              styles.card,
              styles[
                style !== WORDS ? card.value + '-' + style + card.id : style
              ],
              {
                [styles.cardIsLiked]: card.isLiked,
              },
              {
                [styles.cardIsDisliked]: card.isDisliked,
              },
            )}
          >
            <div
              className={styles.title}
              dangerouslySetInnerHTML={createText(card.title)}
            ></div>
            {style === WORDS && (
              <div className={styles.slogan}>
                <p dangerouslySetInnerHTML={createText(card.text)}></p>
              </div>
            )}
            <div className={styles.text}>
              {style !== WORDS && (
                <p dangerouslySetInnerHTML={createText(card.text)}></p>
              )}
              <span
                className={styles.author}
                dangerouslySetInnerHTML={createText(card.author)}
              ></span>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className={styles.cards}>
      <div className={styles.top}>Какая цитата вам ближе?</div>
      {cards[activeCard - 1]?.isLiked && (
        <div className={styles.topClue}>
          <p>Вы выбрали</p>
        </div>
      )}
      {cards[activeCard - 1]?.isDisliked && (
        <div className={classNames(styles.topClue, styles.topClueDislike)}>
          <p>
            Вам <br />
            не&#160;подошло
          </p>
        </div>
      )}
      <Swiper
        onSwiper={setSwiper}
        modules={[EffectCards, Navigation]}
        allowTouchMove={false}
        effect="cards"
        cardsEffect={{
          perSlideOffset: 8,
          perSlideRotate: 10,
          slideShadows: false,
        }}
        slidesPerView={'auto'}
        centeredSlides={true}
        touchReleaseOnEdges={true}
        speed={600}
        navigation={{
          prevEl: '.toLeft',
        }}
        onSlideChange={() => {
          if (swiper) {
            setActiveCard(swiper.realIndex + 1);
          }
        }}
      >
        {renderSlides()}
      </Swiper>
      <div className={styles.buttons}>
        <button
          className={classNames(styles.button, styles.dislike, {
            [styles.buttonIsDisliked]: cards[activeCard - 1]?.isDisliked,
          })}
          onClick={handleDislike}
        >
          <svg
            width="38"
            height="37"
            viewBox="0 0 38 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.3698 6.09009L6.5498 30.9101"
              stroke="#737373"
              strokeWidth="5.84"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.5498 6.09009L31.3698 30.9101"
              stroke="#737373"
              strokeWidth="5.84"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          className={classNames(styles.button, styles.like, {
            [styles.buttonIsLiked]: cards[activeCard - 1]?.isLiked,
          })}
          onClick={handleLike}
        >
          <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_384_56263)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.94562 3.67583C12.5215 3.72797 15.3815 4.95333 18.325 7.74297C21.2632 4.96115 24.1207 3.7384 26.6939 3.68886C29.4053 3.63672 31.6944 4.88815 33.3056 6.76529C36.4811 10.4674 37.1381 16.8028 33.1544 20.7839L33.1518 20.7865L22.0793 31.7574C19.9675 33.8483 16.6825 33.8483 14.5707 31.7574L3.50076 20.7865C-0.495987 16.7871 0.153192 10.4518 3.32869 6.74443C4.9373 4.86729 7.22637 3.61586 9.94301 3.67322L9.94562 3.67583Z"
                fill="#FF4040"
              />
            </g>
            <defs>
              <clipPath id="clip0_384_56263">
                <rect
                  width="36.5"
                  height="36.5"
                  fill="white"
                  transform="translate(0.0698242 0.25)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  );
};
