export const LOVE = 'love';
export const KINDNESS = 'kindness';
export const TRUST = 'trust';
export const UNDERSTANDING = 'understanding';
export const LOYALTY = 'loyalty';
export const RESPECT = 'respect';

export const AI = 'ai';
export const LOVEIS = 'loveis';
export const PENGUINS = 'penguins';
export const WORDS = 'words';

export type TValue = {
  cards: TCard[];
};

export type TCard = {
  id: number;
  value: string;
  title: string;
  text: string;
  author: string;
  links: { [key: string]: string };
  analytics: { [key: string]: string };
  isLiked?: boolean;
  isDisliked?: boolean;
};

type TValues = {
  [key: string]: TValue;
};

export const values: TValues = {
  love: {
    cards: [
      {
        id: 1,
        value: LOVE,
        title: 'Любовь – это…',
        text: '…когда ставишь интересы дорогого тебе человека <span>выше собственных</span>',
        author: 'Николай, персональный менеджер',
        links: {
          ai: 'https://t.me/addstickers/domrfiilovstik1',
          loveis: 'https://t.me/addstickers/domrfloveislovstik1',
          penguins: 'https://t.me/addstickers/domrftotlovstik1',
          words: 'https://t.me/addstickers/domrftextlovstik1',
        },
        analytics: { action: 'question1', label: 'var1' },
      },
      {
        id: 2,
        value: LOVE,
        title: 'Любовь – это…',
        text: '…<span>видеть лучшее</span>, принимать настоящее, помогать для&#160;будущего',
        author: 'Виктория, руководитель проектов',
        links: {
          ai: 'https://t.me/addstickers/domrfiilovstik2',
          loveis: 'https://t.me/addstickers/domrfloveislovstik2',
          penguins: 'https://t.me/addstickers/domrftotlovstik2',
          words: 'https://t.me/addstickers/domrftextlovstik2',
        },
        analytics: { action: 'question1', label: 'var2' },
      },
      {
        id: 3,
        value: LOVE,
        title: 'Любовь – это…',
        text: '…<span>внутренняя тишина</span> и&#160;уверенность',
        author: 'Светлана, руководитель направления',
        links: {
          ai: 'https://t.me/addstickers/domrfiilovstik3',
          loveis: 'https://t.me/addstickers/domrfloveislovstik3',
          penguins: 'https://t.me/addstickers/domrftotlovstik3',
          words: 'https://t.me/addstickers/domrftextlovstik3',
        },
        analytics: { action: 'question1', label: 'var3' },
      },
      {
        id: 4,
        value: LOVE,
        title: 'Любовь – это…',
        text: '…радоваться тому, что твоя работа <span>приносит пользу</span>',
        author: 'Ирина, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiilovstik4',
          loveis: 'https://t.me/addstickers/domrfloveislovstik4',
          penguins: 'https://t.me/addstickers/domrftotlovstik4',
          words: 'https://t.me/addstickers/domrftextlovstik4',
        },
        analytics: { action: 'question1', label: 'var4' },
      },
      {
        id: 5,
        value: LOVE,
        title: 'Любовь – это…',
        text: '…добровольная <span>отдача себя</span> любимому человеку или&#160;делу',
        author: 'Лариса, руководитель направления',
        links: {
          ai: 'https://t.me/addstickers/domrfiilovstik5',
          loveis: 'https://t.me/addstickers/domrfloveislovstik5',
          penguins: 'https://t.me/addstickers/domrftotlovstik5',
          words: 'https://t.me/addstickers/domrftextlovstik5',
        },
        analytics: { action: 'question1', label: 'var5' },
      },
      {
        id: 6,
        value: LOVE,
        title: 'Любовь – это…',
        text: '…то, что позволяет нам <span>с&#160;радостью</span> утром идти на&#160;работу, а&#160;вечером&#160;– домой!',
        author: 'Наталья, руководитель',
        links: {
          ai: 'https://t.me/addstickers/domrfiilovstik6',
          loveis: 'https://t.me/addstickers/domrfloveislovstik6',
          penguins: 'https://t.me/addstickers/domrftotlovstik6',
          words: 'https://t.me/addstickers/domrftextlovstik6',
        },
        analytics: { action: 'question1', label: 'var6' },
      },
    ],
  },
  kindness: {
    cards: [
      {
        id: 1,
        value: KINDNESS,
        title: 'Доброта – это…',
        text: '…уважение права <span>на&#160;ошибку другого человека</span>',
        author:
          'Алексей, руководитель направления в&#160;отделе качества данных',
        links: {
          ai: 'https://t.me/addstickers/domrfiikndstik1',
          loveis: 'https://t.me/addstickers/kndstik1',
          penguins: 'https://t.me/addstickers/domrftotkndstik1',
          words: 'https://t.me/addstickers/domrftextkndstik1',
        },
        analytics: { action: 'question2', label: 'var1' },
      },
      {
        id: 2,
        value: KINDNESS,
        title: 'Доброта – это…',
        text: '…когда даже <span>маленькие поступки</span> делают <nobr>чей-то</nobr> день ярче',
        author: 'Галина, эксперт',
        links: {
          ai: 'https://t.me/addstickers/domrfiikndstik2',
          loveis: 'https://t.me/addstickers/kndstik2',
          penguins: 'https://t.me/addstickers/domrftotkndstik2',
          words: 'https://t.me/addstickers/domrftextkndstik2',
        },
        analytics: { action: 'question2', label: 'var2' },
      },
      {
        id: 3,
        value: KINDNESS,
        title: 'Доброта – это…',
        text: '…когда ты помогаешь другому человеку и&#160;для&#160;этого <span>необязательно быть сильным и&#160;богатым</span>',
        author: 'Ольга, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiikndstik3',
          loveis: 'https://t.me/addstickers/kndstik3',
          penguins: 'https://t.me/addstickers/domrftotkndstik3',
          words: 'https://t.me/addstickers/domrftextkndstik3',
        },
        analytics: { action: 'question2', label: 'var3' },
      },
      {
        id: 4,
        value: KINDNESS,
        title: 'Доброта – это…',
        text: '…бескорыстное стремление протянуть <span>руку помощи</span>',
        author: 'Николай, персональный менеджер',
        links: {
          ai: 'https://t.me/addstickers/domrfiikndstik4',
          loveis: 'https://t.me/addstickers/kndstik4',
          penguins: 'https://t.me/addstickers/domrftotkndstik4',
          words: 'https://t.me/addstickers/domrftextkndstik4',
        },
        analytics: { action: 'question2', label: 'var4' },
      },
      {
        id: 5,
        value: KINDNESS,
        title: 'Доброта – это…',
        text: '…писать рабочие письма так, чтобы <span>их&#160;понимали с&#160;первого раза</span>',
        author: 'Екатерина, руководитель направления',
        links: {
          ai: 'https://t.me/addstickers/domrfiikndstik5',
          loveis: 'https://t.me/addstickers/kndstik5',
          penguins: 'https://t.me/addstickers/domrftotkndstik5',
          words: 'https://t.me/addstickers/domrftextkndstik5',
        },
        analytics: { action: 'question2', label: 'var5' },
      },
      {
        id: 6,
        value: KINDNESS,
        title: 'Доброта – это…',
        text: '…способность прощать ошибки других людей, <span>не&#160;разрушая себя</span>',
        author: 'Анна, ведущий специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiikndstik6',
          loveis: 'https://t.me/addstickers/kndstik6',
          penguins: 'https://t.me/addstickers/domrftotkndstik6',
          words: 'https://t.me/addstickers/domrftextkndstik6',
        },
        analytics: { action: 'question2', label: 'var6' },
      },
    ],
  },
  trust: {
    cards: [
      {
        id: 1,
        value: TRUST,
        title: 'Доверие – это…',
        text: '…когда веришь, что коллеги не&#160;съедят твой обед из&#160;холодильника',
        author: 'Галина, эксперт',
        links: {
          ai: 'https://t.me/addstickers/domrfiitruststik1',
          loveis: 'https://t.me/addstickers/domrfloveistruststik1',
          penguins: 'https://t.me/addstickers/domrftottruststik1',
          words: 'https://t.me/addstickers/domrftexttruststik1',
        },
        analytics: { action: 'question3', label: 'var1' },
      },
      {
        id: 2,
        value: TRUST,
        title: 'Доверие – это…',
        text: '…когда веришь другому, <span>как&#160;себе</span>',
        author: 'Наталья, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiitruststik2',
          loveis: 'https://t.me/addstickers/domrfloveistruststik2',
          penguins: 'https://t.me/addstickers/domrftottruststik2',
          words: 'https://t.me/addstickers/domrftexttruststik2',
        },
        analytics: { action: 'question3', label: 'var2' },
      },
      {
        id: 3,
        value: TRUST,
        title: 'Доверие – это…',
        text: '…кирпичик, на&#160;котором строятся <span>крепкие отношения</span>',
        author: 'Анастасия, специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiitruststik3',
          loveis: 'https://t.me/addstickers/domrfloveistruststik3',
          penguins: 'https://t.me/addstickers/domrftottruststik3',
          words: 'https://t.me/addstickers/domrftexttruststik3',
        },
        analytics: { action: 'question3', label: 'var3' },
      },
      {
        id: 4,
        value: TRUST,
        title: 'Доверие – это…',
        text: '…рассказывать всё другому человеку, <span>не&#160;боясь, что тебя подставят</span>',
        author: 'Михаил, директор КП',
        links: {
          ai: 'https://t.me/addstickers/domrfiitruststik4',
          loveis: 'https://t.me/addstickers/domrfloveistruststik4',
          penguins: 'https://t.me/addstickers/domrftottruststik4',
          words: 'https://t.me/addstickers/domrftexttruststik4',
        },
        analytics: { action: 'question3', label: 'var4' },
      },
      {
        id: 5,
        value: TRUST,
        title: 'Доверие – это…',
        text: '…делиться с&#160;коллегами наболевшим на&#160;«нытингах» и&#160;<span>точно знать, что тебя поддержат</span>',
        author: 'Анна, руководитель',
        links: {
          ai: 'https://t.me/addstickers/domrfiitruststik5',
          loveis: 'https://t.me/addstickers/domrfloveistruststik5',
          penguins: 'https://t.me/addstickers/domrftottruststik5',
          words: 'https://t.me/addstickers/domrftexttruststik5',
        },
        analytics: { action: 'question3', label: 'var5' },
      },
      {
        id: 6,
        value: TRUST,
        title: 'Доверие – это…',
        text: '…открыть свой <span>расчетный лист</span> рядом с&#160;сидящим коллегой',
        author: 'Лилия, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiitruststik6',
          loveis: 'https://t.me/addstickers/domrfloveistruststik6',
          penguins: 'https://t.me/addstickers/domrftottruststik6',
          words: 'https://t.me/addstickers/domrftexttruststik6',
        },
        analytics: { action: 'question3', label: 'var6' },
      },
    ],
  },
  understanding: {
    cards: [
      {
        id: 1,
        value: UNDERSTANDING,
        title: 'Взаимопонимание – это…',
        text: '…молча кивнуть в&#160;ответ на&#160;вопросительный взгляд коллеги,&#160;- <span>«а&#160;не&#160;пора&#160;ли?»</span>',
        author: 'Сергей, руководитель',
        links: {
          ai: 'https://t.me/addstickers/domrfiiunderstik1',
          loveis: 'https://t.me/addstickers/domrfloveisunderstik1',
          penguins: 'https://t.me/addstickers/domrftotunderstik1',
          words: 'https://t.me/addstickers/domrftextunderstik1',
        },
        analytics: { action: 'question4', label: 'var1' },
      },
      {
        id: 2,
        value: UNDERSTANDING,
        title: 'Взаимопонимание – это…',
        text: '…<span>предложить кофе</span> засыпающему коллеге',
        author: 'Наталья, руководитель направления',
        links: {
          ai: 'https://t.me/addstickers/domrfiiunderstik2',
          loveis: 'https://t.me/addstickers/domrfloveisunderstik2',
          penguins: 'https://t.me/addstickers/domrftotunderstik2',
          words: 'https://t.me/addstickers/domrftextunderstik2',
        },
        analytics: { action: 'question4', label: 'var2' },
      },
      {
        id: 3,
        value: UNDERSTANDING,
        title: 'Взаимопонимание – это…',
        text: '…разговор <span>без&#160;слов</span>',
        author: 'Антон, ведущий юрист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiunderstik3',
          loveis: 'https://t.me/addstickers/domrfloveisunderstik3',
          penguins: 'https://t.me/addstickers/domrftotunderstik3',
          words: 'https://t.me/addstickers/domrftextunderstik3',
        },
        analytics: { action: 'question4', label: 'var3' },
      },
      {
        id: 4,
        value: UNDERSTANDING,
        title: 'Взаимопонимание – это…',
        text: '…не&#160;спорить, баг или&#160;фича, а&#160;<span>просто закрывать задачу</span>',
        author: 'Татьяна, руководитель тестирования',
        links: {
          ai: 'https://t.me/addstickers/domrfiiunderstik4',
          loveis: 'https://t.me/addstickers/domrfloveisunderstik4',
          penguins: 'https://t.me/addstickers/domrftotunderstik4',
          words: 'https://t.me/addstickers/domrftextunderstik4',
        },
        analytics: { action: 'question4', label: 'var4' },
      },
      {
        id: 5,
        value: UNDERSTANDING,
        title: 'Взаимопонимание – это…',
        text: '…скидывать <span>одинаковые мемы</span> в&#160;рабочие чаты',
        author: 'Анастасия, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiunderstik5',
          loveis: 'https://t.me/addstickers/domrfloveisunderstik5',
          penguins: 'https://t.me/addstickers/domrftotunderstik5',
          words: 'https://t.me/addstickers/domrftextunderstik5',
        },
        analytics: { action: 'question4', label: 'var5' },
      },
      {
        id: 6,
        value: UNDERSTANDING,
        title: 'Взаимопонимание – это…',
        text: '…когда друг друга слушаешь, <span>а&#160;главное&#160;- слышишь</span>',
        author: 'Ольга, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiunderstik6',
          loveis: 'https://t.me/addstickers/domrfloveisunderstik6',
          penguins: 'https://t.me/addstickers/domrftotunderstik6',
          words: 'https://t.me/addstickers/domrftextunderstik6',
        },
        analytics: { action: 'question4', label: 'var6' },
      },
    ],
  },
  loyalty: {
    cards: [
      {
        id: 1,
        value: LOYALTY,
        title: 'Верность – это…',
        text: '…<span>нормальное качество для&#160;людей</span>, которые успешно прошли эволюцию с&#160;каменного века',
        author: 'Татьяна, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiloystik1',
          loveis: 'https://t.me/addstickers/domrfloveisloystik1',
          penguins: 'https://t.me/addstickers/domrftotloystik1',
          words: 'https://t.me/addstickers/domrftextloystik1',
        },
        analytics: { action: 'question5', label: 'var1' },
      },
      {
        id: 2,
        value: LOYALTY,
        title: 'Верность – это…',
        text: '…уважать свой выбор <span>и&#160;не&#160;предавать его</span>',
        author: 'Яна, ведущий специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiloystik2',
          loveis: 'https://t.me/addstickers/domrfloveisloystik2',
          penguins: 'https://t.me/addstickers/domrftotloystik2',
          words: 'https://t.me/addstickers/domrftextloystik2',
        },
        analytics: { action: 'question5', label: 'var2' },
      },
      {
        id: 3,
        value: LOYALTY,
        title: 'Верность – это…',
        text: '…<span>постоянство</span> в&#160;словах и&#160;обещаниях',
        author: 'Илья, руководитель направления',
        links: {
          ai: 'https://t.me/addstickers/domrfiiloystik3',
          loveis: 'https://t.me/addstickers/domrfloveisloystik3',
          penguins: 'https://t.me/addstickers/domrftotloystik3',
          words: 'https://t.me/addstickers/domrftextloystik3',
        },
        analytics: { action: 'question5', label: 'var3' },
      },
      {
        id: 4,
        value: LOYALTY,
        title: 'Верность – это…',
        text: '…единственная валюта, которая <span>никогда не&#160;обесценится</span>',
        author: 'Ольга, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiloystik4',
          loveis: 'https://t.me/addstickers/domrfloveisloystik4',
          penguins: 'https://t.me/addstickers/domrftotloystik4',
          words: 'https://t.me/addstickers/domrftextloystik4',
        },
        analytics: { action: 'question5', label: 'var4' },
      },
      {
        id: 5,
        value: LOYALTY,
        title: 'Верность – это…',
        text: '…<span>не&#160;фрилансить</span> на&#160;стороне',
        author: 'Анастасия, специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiloystik5',
          loveis: 'https://t.me/addstickers/domrfloveisloystik5',
          penguins: 'https://t.me/addstickers/domrftotloystik5',
          words: 'https://t.me/addstickers/domrftextloystik5',
        },
        analytics: { action: 'question5', label: 'var5' },
      },
      {
        id: 6,
        value: LOYALTY,
        title: 'Верность – это…',
        text: '…умение в&#160;новых обстоятельствах <span>выбирать и&#160;оставаться с&#160;ним/с&#160;ней</span>',
        author: 'Анна, ведущий специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiiloystik6',
          loveis: 'https://t.me/addstickers/domrfloveisloystik6',
          penguins: 'https://t.me/addstickers/domrftotloystik6',
          words: 'https://t.me/addstickers/domrftextloystik6',
        },
        analytics: { action: 'question5', label: 'var6' },
      },
    ],
  },
  respect: {
    cards: [
      {
        id: 1,
        value: RESPECT,
        title: 'Уважение – это…',
        text: '…выслушивать идеи коллег, даже если они выглядят, <span>как план по&#160;захвату мира</span>',
        author: 'Галина, эксперт',
        links: {
          ai: 'https://t.me/addstickers/domrfiirespstik1',
          loveis: 'https://t.me/addstickers/domrfloveisrespstik1',
          penguins: 'https://t.me/addstickers/domrftotrespstik1',
          words: 'https://t.me/addstickers/domrftextrespstik1',
        },
        analytics: { action: 'question6', label: 'var1' },
      },
      {
        id: 2,
        value: RESPECT,
        title: 'Уважение – это…',
        text: '…возможность <span>быть услышанным в&#160;коллективе</span>',
        author: 'Ксения, ведущий юрист',
        links: {
          ai: 'https://t.me/addstickers/domrfiirespstik2',
          loveis: 'https://t.me/addstickers/domrfloveisrespstik2',
          penguins: 'https://t.me/addstickers/domrftotrespstik2',
          words: 'https://t.me/addstickers/domrftextrespstik2',
        },
        analytics: { action: 'question6', label: 'var2' },
      },
      {
        id: 3,
        value: RESPECT,
        title: 'Уважение – это…',
        text: '…<span>не&#160;кричать на&#160;коллег</span>, даже если очень хочется',
        author: 'Людмила, главный специалист по&#160;охране труда',
        links: {
          ai: 'https://t.me/addstickers/domrfiirespstik3',
          loveis: 'https://t.me/addstickers/domrfloveisrespstik3',
          penguins: 'https://t.me/addstickers/domrftotrespstik3',
          words: 'https://t.me/addstickers/domrftextrespstik3',
        },
        analytics: { action: 'question6', label: 'var3' },
      },
      {
        id: 4,
        value: RESPECT,
        title: 'Уважение – это…',
        text: '…относиться к&#160;каждому клиенту, как <span>к&#160;привилегированному</span>',
        author: 'Екатерина, руководитель направления',
        links: {
          ai: 'https://t.me/addstickers/domrfiirespstik4',
          loveis: 'https://t.me/addstickers/domrfloveisrespstik4',
          penguins: 'https://t.me/addstickers/domrftotrespstik4',
          words: 'https://t.me/addstickers/domrftextrespstik4',
        },
        analytics: { action: 'question6', label: 'var4' },
      },
      {
        id: 5,
        value: RESPECT,
        title: 'Уважение – это…',
        text: '…не&#160;писать <span>после рабочего времени</span>',
        author: 'Кумри, персональный менеджер',
        links: {
          ai: 'https://t.me/addstickers/domrfiirespstik5',
          loveis: 'https://t.me/addstickers/domrfloveisrespstik5',
          penguins: 'https://t.me/addstickers/domrftotrespstik5',
          words: 'https://t.me/addstickers/domrftextrespstik5',
        },
        analytics: { action: 'question6', label: 'var5' },
      },
      {
        id: 6,
        value: RESPECT,
        title: 'Уважение – это…',
        text: '…когда работодатель без&#160;напоминаний <span>индексирует зарплату</span>',
        author: 'Надежда, главный специалист',
        links: {
          ai: 'https://t.me/addstickers/domrfiirespstik6',
          loveis: 'https://t.me/addstickers/domrfloveisrespstik6',
          penguins: 'https://t.me/addstickers/domrftotrespstik6',
          words: 'https://t.me/addstickers/domrftextrespstik6',
        },
        analytics: { action: 'question6', label: 'var6' },
      },
    ],
  },
};
