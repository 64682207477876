import { useEffect, useState } from 'react';
import styles from './Final.module.scss';
import classNames from 'classnames';

import { TCard, AI, LOVEIS, PENGUINS, WORDS } from '@/src/constants';
import { getImg } from '@/src/utils/getImg';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
import sendEventToCounters from '@/src/counterEvents';

import like from '../../images/like.svg';

type TProps = {
  style: string;
  finalCards: TCard[];
};

export const Final = ({ style, finalCards }: TProps) => {
  const [isStickersPopup, setIsStickersPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  const isMediaQueryLess1020 = useMediaQuery('(max-width: 1020px)');

  const handleOpenStickersPopup = () => {
    sendAnalytics('downloadStickers');
    setIsStickersPopup(true);
  };

  const handleCloseStickersPopup = () => {
    setIsStickersPopup(false);
  };

  const getLink = () => {
    switch (style) {
      case AI:
        return 'https://t.me/addstickers/domrfii';
      case LOVEIS:
        return 'https://t.me/addstickers/domrfloveis';
      case PENGUINS:
        return 'https://t.me/addstickers/domrftotem';
      case WORDS:
        return 'https://t.me/addstickers/domrfonlytext';
      default:
        break;
    }
  };

  const sendPopupAnalytics = () => {
    if (isMediaQueryLess1020) {
      sendEventToCounters({ action: 'popUp', label: 'mobile' });
    } else {
      sendEventToCounters({ action: 'popUp', label: 'desktop' });
    }
  };

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'results', label });
  };

  const renderStickers = () => {
    return finalCards.map((card: TCard, index: number) => {
      return (
        <div
          key={card.value}
          className={classNames(
            styles.wrapper,
            styles['wrapper' + (index + 1)],
          )}
        >
          <a
            href={card.links[style]}
            target="_blank"
            rel="noreferrer"
            className={classNames(
              styles.sticker,
              { [styles.stickerWords]: style === WORDS },
              styles[card.value + '-' + style + card.id],
            )}
            onClick={sendPopupAnalytics}
          >
            {style === AI && (
              <img src={getImg(card.value + style + card.id)} alt="" />
            )}
            <div className={styles.stickerText}>Скачать</div>
          </a>
        </div>
      );
    });
  };

  return (
    <div
      className={classNames(styles.final, {
        [styles.finalBlur]: isStickersPopup && isMediaQueryLess1020,
      })}
    >
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.content}>
            {finalCards.length === 0 && (
              <motion.div
                initial={
                  isMediaQueryLess1020
                    ? { x: 20, rotate: 0 }
                    : { x: 100, rotate: 0 }
                }
                whileInView={{ x: 0, rotate: -10 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className={classNames(
                  styles.card,
                  styles.left,
                  styles[style !== WORDS ? style + '-1' : style],
                )}
                viewport={{
                  once: true,
                }}
              >
                {style === AI ? (
                  <div className={styles.cardTitle}>
                    <p>Доброта — это…</p>
                  </div>
                ) : (
                  <div className={styles.cardTitle}>
                    <p>Любовь — это…</p>
                  </div>
                )}
                {style === WORDS && (
                  <>
                    <div className={styles.slogan}>
                      <p>
                        …когда ставишь интересы дорогого тебе человека{' '}
                        <span>выше собственных</span>
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span className={styles.author}>
                        Николай, персональный менеджер
                      </span>
                    </div>
                  </>
                )}
              </motion.div>
            )}
            <div className={styles.main}>
              <div className={styles.icon}>
                <img src={like} alt="" />
              </div>
              <div className={styles.title}>У&#160;нас так много общего!</div>
              <div className={styles.subtitle}>
                Благодарим за&#160;игру и&#160;дарим вам набор стикеров!
              </div>
              <p>
                Быть в&#160;команде с&#160;теми, кто разделяет твои
                взгляды&#160;– бесценно! <br />
                Присоединяйтесь к&#160;нам, ведь ДОМ.РФ&#160;– это
                по&#160;любви!
              </p>
              <a
                href="https://xn--d1aqf.xn--p1ai/career/vacancies/?utm_source=domrf_hh&utm_medium=referral&utm_campaign=special_project"
                target="_blank"
                rel="noreferrer"
                className={classNames('button', styles.button)}
                onClick={() => sendAnalytics('work')}
              >
                Работа по&#160;любви
              </a>
            </div>
            {finalCards.length === 0 && (
              <motion.div
                initial={
                  isMediaQueryLess1020
                    ? { x: -20, rotate: 0 }
                    : { x: -100, rotate: 0 }
                }
                whileInView={{ x: 0, rotate: 10 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className={classNames(
                  styles.card,
                  styles.right,
                  styles[style !== WORDS ? style + '-2' : style],
                )}
                viewport={{
                  once: true,
                }}
              >
                {style === AI ? (
                  <div className={styles.cardTitle}>
                    <p>Уважение — это…</p>
                  </div>
                ) : (
                  <div className={styles.cardTitle}>
                    <p>Доверие – это…</p>
                  </div>
                )}
                {style === WORDS && (
                  <>
                    <div className={styles.slogan}>
                      <p>
                        …кирпичик, на&#160;котором строятся{' '}
                        <span>крепкие отношения</span>
                      </p>
                    </div>
                    <div className={styles.text}>
                      <span className={styles.author}>
                        Анастасия, специалист
                      </span>
                    </div>
                  </>
                )}
              </motion.div>
            )}
            {finalCards.length > 0 && !isMediaQueryLess1020 && (
              <div
                className={classNames(
                  styles.stickers,
                  styles['stickers' + finalCards.length],
                )}
              >
                {renderStickers()}
              </div>
            )}
            {isStickersPopup && isMediaQueryLess1020 && (
              <div
                className={classNames(styles.stickersMob, {
                  [styles.stickersMobTop]: finalCards.length < 5,
                })}
              >
                <div className={styles.popupTitle}>Выбранные стикеры</div>
                <button
                  className={styles.close}
                  onClick={handleCloseStickersPopup}
                ></button>
                <div className={styles.list}>{renderStickers()}</div>
                <a
                  href="https://xn--d1aqf.xn--p1ai/career/vacancies/?utm_source=domrf_hh&utm_medium=referral&utm_campaign=special_project"
                  target="_blank"
                  rel="noreferrer"
                  className={classNames('button', styles.button)}
                  onClick={() => sendAnalytics('work')}
                >
                  Работа по&#160;любви
                </a>
              </div>
            )}
            <a
              href={getLink()}
              target="_blank"
              rel="noreferrer"
              className={classNames(styles.stickerpack, {
                [styles.stickerpackWithoutStickers]: finalCards.length === 0,
              })}
              onClick={() => sendAnalytics('downloadAll')}
            >
              <p>
                Скачать <span>полный стикерпак!</span>
              </p>
            </a>
            {finalCards.length > 0 && isMediaQueryLess1020 && (
              <button
                className={styles.stickersButton}
                onClick={handleOpenStickersPopup}
              >
                <p>
                  Скачать <span>выбранные стикеры</span>
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
