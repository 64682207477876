import { useEffect } from 'react';
import styles from './Home.module.scss';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  changePage: (s: string) => void;
};

export const Home = ({ changePage }: TProps) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  const isMediaQueryLess1020 = useMediaQuery('(max-width: 1020px)');

  const handleGoNext = () => {
    sendAnalytics('start');
    changePage('rules');
  };

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'landing', label });
  };

  return (
    <div className={styles.home}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.top}>
            <motion.div
              initial={
                isMediaQueryLess1020
                  ? { x: 20, rotate: 0 }
                  : { x: 100, rotate: 0 }
              }
              whileInView={
                isMediaQueryLess1020
                  ? { x: 0, rotate: -15 }
                  : { x: 0, rotate: -10 }
              }
              transition={{ duration: 0.5, delay: 0.2 }}
              className={classNames(styles.card, styles.left)}
              viewport={{
                once: true,
              }}
            >
              <p>Доброта — это…</p>
            </motion.div>
            <div className={styles.main}>
              <div className={styles.wrapper}>
                <div className={styles.image}></div>
                <div className={styles.title}>
                  ДОМ.РФ&#160;– это&#160; по&#160;любви!
                </div>
                {isMediaQueryLess1020 && (
                  <div className={styles.subtitle}>
                    Насколько ваши ценности совпадают с&#160;командой ДОМ.РФ
                  </div>
                )}
              </div>
              <p>
                <span>Доброта</span> и&#160;<span>уважение</span>,{' '}
                <span>верность</span> и&#160;<span>доверие</span>&#160;–{' '}
                соберите свой стикерпак для&#160;самых важных диалогов,
                а&#160;заодно проверьте, насколько ваши ценности совпадают
                с&#160;командой ДОМ.РФ
              </p>
            </div>
            <motion.div
              initial={
                isMediaQueryLess1020
                  ? { x: -20, rotate: 0 }
                  : { x: -100, rotate: 0 }
              }
              whileInView={
                isMediaQueryLess1020
                  ? { x: 0, rotate: 15 }
                  : { x: 0, rotate: 10 }
              }
              transition={{ duration: 0.5, delay: 0.2 }}
              className={classNames(styles.card, styles.right)}
              viewport={{
                once: true,
              }}
            >
              <p>Любовь — это…</p>
            </motion.div>
          </div>
          <div className={styles.bottom}>
            <div
              className={classNames('button', styles.button)}
              onClick={handleGoNext}
            >
              Начать
            </div>
            <div className={styles.slogan}>
              <p>Вы с&#160;нами?</p>
            </div>
            <div className={styles.stickerpack}>
              <p>
                Ваш стикерпак <span>в&#160;финале!</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
