import { useEffect, useState } from 'react';
import styles from './Game.module.scss';

import { Cards } from './components';
import { values, TCard } from '@/src/constants';
import { getRandomIndex } from '@/src/utils/getRandomIndex';

type TProps = {
  changePage: (s: string) => void;
  style: string;
  cardsToFinal: (cards: TCard[]) => void;
};

export const Game = ({ changePage, style, cardsToFinal }: TProps) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  const [cards, setCards] = useState<TCard[]>([]);

  useEffect(() => {
    const cards: TCard[] = [];
    Object.keys(values).forEach((value) => {
      const valueCards = values[value].cards;
      const valueCard = {
        ...valueCards[getRandomIndex(valueCards.length)],
        isLiked: false,
        isDisliked: false,
      };
      cards.push(valueCard);
    });
    setCards(cards);
  }, []);

  const likeCard = (card: TCard) => {
    setCards(
      cards.map((c) =>
        c === card ? { ...c, isLiked: true, isDisliked: false } : c,
      ),
    );
  };

  const dislikeCard = (card: TCard) => {
    setCards(
      cards.map((c) =>
        c === card ? { ...c, isLiked: false, isDisliked: true } : c,
      ),
    );
  };

  const goFinal = () => {
    cardsToFinal(cards.filter((c) => c.isLiked));
    changePage('final');
  };

  return (
    <div className={styles.game}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.hidden}>
            <Cards
              style={style}
              cards={cards}
              likeCard={likeCard}
              dislikeCard={dislikeCard}
              goFinal={goFinal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
